
import { mapGetters, mapActions } from 'vuex';
import { COMPONENT_NAME } from '@/utils/amplitudeNames.js';

export default {
  name: 'ReviewHelpful',
  props: {
    supportCount: { type: Number, default: 0 },
    id: { type: Number, required: true },
  },
  data() {
    return {
      helpfulCount: 0,
      isPending: false,
    };
  },
  computed: {
    ...mapGetters('user/review', ['helpfulReviewIds']),
    helpfulProp: () => COMPONENT_NAME.helpful,
    isAlreadyHelpful: ({ helpfulReviewIds, id }) =>
      helpfulReviewIds.includes(id),
  },
  mounted() {
    this.helpfulCount = this.supportCount;
  },
  methods: {
    ...mapActions('user/review', ['toggleThisReviewHelpful']),
    async onClickHelpful() {
      if (!this.$auth.loggedIn) {
        // requestToken: kakaoAuth 플러그인 안에 글로벌 메서드로 있음
        this.requestToken({
          section: null,
          productId: this.$route.params.id || undefined,
          componentName: COMPONENT_NAME.likeReview,
        });
        return;
      }

      if (this.isPending) return;

      try {
        this.isPending = true;
        const res = await this.toggleThisReviewHelpful({
          reviewId: this.id,
        });

        res.data?.isHelpful ? this.helpfulCount++ : this.helpfulCount--;
        this.logLikeReviewEvent(res.data?.isHelpful);
      } catch (e) {
        console.error(e);
      } finally {
        this.isPending = false;
      }
    },
    logLikeReviewEvent(state) {
      this.logUserEvent('click_like_review', { state });
    },
  },
};
