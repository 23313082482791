import { render, staticRenderFns } from "./ReviewItem.vue?vue&type=template&id=631b3a7c&scoped=true"
import script from "./ReviewItem.vue?vue&type=script&lang=js"
export * from "./ReviewItem.vue?vue&type=script&lang=js"
import style0 from "./ReviewItem.vue?vue&type=style&index=0&id=631b3a7c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631b3a7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewHeader: require('/home/runner/work/gguge.web/gguge.web/components/review/ReviewHeader.vue').default,ReviewContents: require('/home/runner/work/gguge.web/gguge.web/components/review/ReviewContents.vue').default,ReviewAdmin: require('/home/runner/work/gguge.web/gguge.web/components/review/ReviewAdmin.vue').default,ReviewHelpful: require('/home/runner/work/gguge.web/gguge.web/components/review/ReviewHelpful.vue').default})
